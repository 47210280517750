import { useContext } from 'react'
import { GuestOrderContext, GuestOrderContextType } from 'state/context/GuestOrderContext'

export const useGuestOrderContext = (): GuestOrderContextType => {
  const context = useContext(GuestOrderContext)

  if (context === undefined) {
    throw new Error('useGuestOrderContext must be used inside GuestOrderContext.Provider')
  }

  return context
}
