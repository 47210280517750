import { ClientError } from 'lib/api/ClientError'
import { api } from 'lib/api/base'
import { ENDPOINTS } from 'lib/api/endpoints'
import { AddAccountErrorActions, CurrentUser } from 'types'
import {
  formatUserResponse,
  LegacyAuthResponse,
  UserAuthResponse,
} from 'lib/api/user/utils/formatUserResponse'
import { isApiError, normalizeError } from 'utils/commonUtils'
import TagManager from '@sooro-io/react-gtm-module'
import { isAxiosError } from 'axios'
import { authorizationCheck } from 'lib/api/user'

export type LogInResponse = LegacyAuthResponse | UserAuthResponse

const isUserAuthResponse = (response: LogInResponse): response is UserAuthResponse => {
  return (response as UserAuthResponse).brand_id !== undefined
}

interface LogInArgs {
  password: string
  username: string
}

export const logInConstants = {
  action: 'login',
}
export const logIn = async ({ password, username }: LogInArgs): Promise<CurrentUser | void> => {
  try {
    try {
      const { data } = await api.post<LogInResponse>(ENDPOINTS.user, {
        ...logInConstants,
        password,
        user_name: username,
      })

      if (isUserAuthResponse(data)) {
        return formatUserResponse(data)
      }
    } catch (e) {
      // User provided correct credentials but needs to update their account information
      if (
        isAxiosError(e) &&
        e?.response?.data?.auth_action?.name === AddAccountErrorActions.REDIRECT_UPDATE_ACCOUNT
      ) {
        const token = e?.response?.data?.auth_action?.token
        return await authorizationCheck({ token })
      }

      throw e
    }

    TagManager.dataLayer({
      dataLayer: {
        event: 'UserSignedIn',
        user_name: username,
      },
    })

    // If the log in credentials are for legacy ReturnCenter push the user there
    // and automatically log them in
    const legacyUrl = (import.meta.env.VITE_LEGACY_LOGIN_URL as string)
      .replace('[user_name]', username)
      .replace('[password]', password)
    window.location.replace(legacyUrl)
  } catch (e) {
    if (isApiError(e)) {
      throw new ClientError(normalizeError(e).message)
    } else {
      throw e
    }
  }
}
