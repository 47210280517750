import { BaseApiResult } from 'types'
import { api } from 'lib/api/base'
import { ENDPOINTS } from '../endpoints'
import { getMixpanelContext } from 'utils/commonUtils'

type TMixpanelApiPayload = {
  [k: string]: string | number | boolean
}

/**
 * Track event in Mixpanel
 *
 * @param eventName Name of the event how it will appear in Mixpanel
 * @param payload Any data associated with the event
 * @param scrURL URL of the page that initiated the event (optional). If not
 *        provided, a current URL will be used as `window.location.href`.
 *        However, there are cases when state update can lead to current page
 *        URL change before mixpanel event tracker is invoked. In this cases
 *        it expected that caller will provide the correct value.
 * @returns Promise<BaseApiResult>
 */
export const mixpanelTrack = async (
  eventName: string,
  payload: TMixpanelApiPayload,
  scrURL?: string,
): Promise<BaseApiResult> => {
  const agentMetadata = await getMixpanelContext(scrURL)
  const { data } = await api.post<BaseApiResult>(ENDPOINTS.mixpanel, {
    action: 'track',
    data: payload,
    event_name: eventName,
    src_ip: agentMetadata.srcIP,
    src_url: agentMetadata.srcURL,
    user_agent: agentMetadata.userAgent,
  })

  return data
}

export enum MixpanelEvents {
  ACCOUNT_CREATED_WITH_PROMO = 'Account with promo code created',
  ORDER_CREATED_WITH_PROMO = 'Order with promo code created',
  PROMO_LINK_VISITED = 'Link with promo code visited',
}
