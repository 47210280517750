import classNames from 'classnames'

import styles from './HeaderToggle.module.scss'

export type Props = {
  active: boolean
  toggle: () => void
}

export const HeaderToggle = ({ active, toggle }: Props): JSX.Element => {
  return (
    <button
      aria-label="Menu toggle"
      className={classNames(styles.HeaderToggle, { [styles.HeaderToggleActive]: active })}
      onClick={toggle}
    >
      <div className={styles.HeaderToggleInner} />
    </button>
  )
}
