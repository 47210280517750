import * as React from 'react'

import { Country, SelectOption, UsState } from 'types'
import { getCountries, getUsStates } from 'lib/api/setting'
import { useAppContext } from 'hooks/useAppContext'

export const STATIC_DATA_STORAGE_KEY = 'RCC_STATIC_DATA'

type ContextValue = {
  countries: Country[]
  stateOptions: SelectOption[] // used for building <select> options
  states: UsState[]
}

const StaticDataContext = React.createContext<ContextValue | undefined>(undefined)

type StaticDataProviderProps = {
  children: React.ReactNode
}

const StaticDataProvider = ({ children }: StaticDataProviderProps): JSX.Element => {
  const { user } = useAppContext()
  const token = user?.token

  const [countries, setCountries] = React.useState<Country[]>([])
  const [states, setStates] = React.useState<UsState[]>([])

  React.useEffect(() => {
    const storedStaticDataString = window.sessionStorage.getItem(STATIC_DATA_STORAGE_KEY)
    if (storedStaticDataString) {
      const storedStaticData = JSON.parse(storedStaticDataString) as ContextValue

      if (storedStaticData.countries.length > 0) {
        setCountries(storedStaticData.countries)
      } else if (token) {
        void getCountries().then(setCountries)
      }

      if (storedStaticData.states.length > 0) {
        setStates(storedStaticData.states)
      } else {
        void getUsStates().then(setStates)
      }
    } else {
      if (token) {
        void getCountries().then(setCountries)
      }

      void getUsStates().then(setStates)
    }
  }, [token])

  React.useEffect(() => {
    window.sessionStorage.setItem(
      STATIC_DATA_STORAGE_KEY,
      JSON.stringify({
        countries,
        states,
      }),
    )
  }, [countries, states])

  const value = React.useMemo(
    () => ({
      countries,
      stateOptions: [{ label: '', value: '' }].concat(
        states.map(state => ({
          label: state.state,
          value: state.state,
        })),
      ),
      states,
    }),
    [countries, states],
  )

  return <StaticDataContext.Provider value={value}>{children}</StaticDataContext.Provider>
}

const useStaticData = (): ContextValue => {
  const context = React.useContext(StaticDataContext)

  if (context === undefined) {
    throw new Error('useStaticData must be used within an StaticDataProvider')
  }

  return context
}

export { StaticDataProvider, useStaticData }
