import { noAuthApi } from 'lib/api/base'
import { ENDPOINTS } from 'lib/api/endpoints'
import { BaseApiResult, BrandParameter, PromoCodeParameter } from 'types'

export type BrandingParameters = {
  custom_url?: string
  font: string
  font_color: string
  login_image: string
  login_text?: string
  logo: string
  navigation_bar_color: string
  primary_color: string
}

type GetThemeResponse = {
  branding_parameters: BrandingParameters
}

type GetPromoCodeResponse = {
  paradigm_id: number
  promo_code: string
  promo_code_id: number
  single_kit: boolean
}

type GetSettingsForPathResponse = BaseApiResult &
  Partial<GetThemeResponse> &
  Partial<GetPromoCodeResponse>

export const getSettingsForPath = async (
  path = '',
): Promise<BrandParameter | PromoCodeParameter> => {
  const { data } = await noAuthApi.get<GetSettingsForPathResponse>(ENDPOINTS.setting, {
    params: {
      action: 'get_settings_for_path',
      path,
    },
  })

  if (data.branding_parameters) {
    const { branding_parameters } = data as GetThemeResponse

    return {
      font: branding_parameters.font,
      fontColor: branding_parameters.font_color,
      loginImage: branding_parameters.login_image,
      logo: branding_parameters.logo,
      navigationBarColor: branding_parameters.navigation_bar_color,
      primaryColor: branding_parameters.primary_color,
      type: 'theme',
    }
  } else {
    const { paradigm_id, promo_code, promo_code_id, single_kit } = data as GetPromoCodeResponse

    return {
      paradigmId: paradigm_id,
      promoCode: promo_code,
      promoCodeId: promo_code_id,
      singleKit: single_kit,
      type: 'promo',
    }
  }
}
