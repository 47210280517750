import { GuestPageResponse, GuestPageExtraData, GuestSettingsResponse } from 'lib/api/types'
import { createContext, JSX, ReactNode, useState } from 'react'
import { GuestQuote } from 'types'

type Props = {
  children: ReactNode
}

export type GuestOrderContextType = {
  guestPageExtraData: GuestPageExtraData | null
  guestQuote: GuestQuote | null
  guestSettings: GuestSettingsResponse | null
  setGuestOrder: (assetsResponse: GuestPageResponse) => void
  setGuestOrderSettings: (settingsResponse: GuestSettingsResponse) => void
}

const GuestOrderContext = createContext<GuestOrderContextType | undefined>(undefined)

const GuestOrderProvider = ({ children }: Props): JSX.Element => {
  const [guestQuote, setGuestQuote] = useState<GuestQuote | null>(null)
  const [guestPageExtraData, setGuestPageExtraData] = useState<GuestPageExtraData | null>(null)
  const [guestSettings, setGuestSettings] = useState<GuestSettingsResponse | null>(null)

  const setGuestOrder = (res: GuestPageResponse) => {
    setGuestQuote(res.quote)
    setGuestPageExtraData(res.page_extra_data || null)
  }

  const setGuestOrderSettings = (res: GuestSettingsResponse) => {
    setGuestSettings(res)
  }

  return (
    <GuestOrderContext.Provider
      value={{
        guestPageExtraData,
        guestQuote,
        guestSettings,
        setGuestOrder,
        setGuestOrderSettings,
      }}
    >
      {children}
    </GuestOrderContext.Provider>
  )
}

export { GuestOrderProvider, GuestOrderContext }
