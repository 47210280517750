import axios from 'axios'

import { cacheAdapter } from './cacheAdapter'

const API_KEY = import.meta.env.VITE_API_KEY || ''
const BASE_URL = import.meta.env.VITE_API_URL || ''

export const api = axios.create({
  adapter: cacheAdapter,
  auth: {
    password: '',
    username: API_KEY,
  },
  baseURL: BASE_URL,
  withCredentials: true,
})

export const noAuthApi = axios.create({
  adapter: cacheAdapter,
  auth: {
    password: '',
    username: API_KEY,
  },
  baseURL: BASE_URL,
})
