import { useEffect, useState } from 'react'

import { Modal } from 'components/Modal'
import { Loader } from 'components/Loader'
import { normalizeError, randomInt } from 'utils/commonUtils'
import { noAuthApi } from 'lib/api/base'
import { ENDPOINTS } from 'lib/api/endpoints'
import { BaseApiResult } from 'types'

import styles from './PasswordSuggestionModal.module.scss'

type TPasswordSuggestionModalProps = {
  hide: () => void
  onPasswordSelect: (password: string) => void
  show: boolean
}

type TPasswordSuggestionResponse = BaseApiResult & {
  passwords: string[]
}

export const PasswordSuggestionModal = ({
  show,
  hide,
  onPasswordSelect,
}: TPasswordSuggestionModalProps): JSX.Element => {
  const [loading, setLoading] = useState(false)
  const [loadingError, setLoadingError] = useState('')
  const [suggestions, setSuggestions] = useState<string[]>([])

  const onPasswordSelectLocal = (password: string): void => {
    onPasswordSelect(password)
    hide()
  }

  const loadPwdSeggestions = async (): Promise<void> => {
    try {
      setLoading(true)
      setLoadingError('')

      const { data: response } = await noAuthApi.get<TPasswordSuggestionResponse>(ENDPOINTS.user, {
        params: {
          action: 'suggest_password',
          count: 3,
        },
      })

      setSuggestions(response.passwords)
    } catch (e) {
      setLoadingError(normalizeError(e).message)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    if (show) {
      void loadPwdSeggestions()
    }
  }, [show])

  return (
    <Modal
      title="Password Suggestions"
      formId={`PasswordSuggestionModal + ${randomInt()}`}
      show={show}
      hide={hide}
      hideSubmit
    >
      {loading ? (
        <Loader />
      ) : loadingError ? (
        <div className={styles.ErrorText}>{loadingError}</div>
      ) : (
        <>
          {suggestions.length === 0 ? (
            <h5>No suggestions found</h5>
          ) : (
            <>
              <p>
                Select any password below and it will be automatically inserted into the
                corresponding password field:
              </p>
              <div className={styles.SuggestionList}>
                {suggestions.map(password => (
                  <button key={password} onClick={() => onPasswordSelectLocal(password)}>
                    <code>{password}</code>
                  </button>
                ))}
              </div>
            </>
          )}
        </>
      )}
    </Modal>
  )
}
