import { api } from 'lib/api/base'
import { ENDPOINTS } from 'lib/api/endpoints'
import { BaseApiResult, UsState } from 'types'

export type GetUsStatesResponse = BaseApiResult & {
  state: UsState[]
}

type GetUsStates = () => Promise<UsState[]>
export const getUsStates: GetUsStates = async () => {
  const {
    data: { state: UsStates },
  } = await api.get<GetUsStatesResponse>(ENDPOINTS.setting, {
    params: {
      action: 'get_states',
      country: 'us',
    },
  })

  return UsStates
}
