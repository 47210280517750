import * as React from 'react'
import classNames from 'classnames'
import Container from 'react-bootstrap/Container'
import { Helmet } from 'react-helmet'

import { Footer } from 'components/Footer'
import { Header } from 'components/Header'

import styles from './Page.module.scss'

export type Props = {
  children?: React.ReactNode
  description?: string
  fitOnScreen?: boolean
  headerActions?: React.ReactNode
  hideFooter?: boolean
  hideHeader?: boolean
  title?: string
}

export const Page = ({
  children,
  description = 'OnePak Partner Community',
  fitOnScreen = true,
  headerActions,
  hideFooter = false,
  hideHeader = false,
  title = 'ReturnCenter Community',
}: Props): JSX.Element => (
  <div className={classNames(styles.Page, { [styles.FitOnScreen]: fitOnScreen })}>
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={description} />
    </Helmet>

    {!hideHeader && <Header additionalActions={headerActions} />}

    <div className={styles.Container}>
      <Container fluid className={styles.Children}>
        {children}
      </Container>
    </div>

    {hideFooter || <Footer />}
  </div>
)
