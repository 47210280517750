import { api } from 'lib/api/base'
import { ENDPOINTS } from 'lib/api/endpoints'
import { GuestSettingsResponse } from 'lib/api/types'
import { GuestIntakeOptions } from 'pages/OrderPages/pages/GuestRedirectPage'

export const getSettingsForGuest = async (guestIntakeOptions: GuestIntakeOptions) => {
  const { data } = await api.get<GuestSettingsResponse>(ENDPOINTS.setting, {
    params: { ...guestIntakeOptions, action: 'get_settings_for_path' },
  })

  return data
}
