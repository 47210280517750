import { useEffect, useState } from 'react'
import { Form, Button, Row } from 'react-bootstrap'
import { Formik } from 'formik'
import { useHistory, useParams } from 'react-router-dom'

import { authorizePasswordChange, changePassword } from 'lib/api/user'
import { Loader } from 'components/Loader'
import { UNAUTHORIZED_ROUTES } from 'Routes'
import { useUnauthorizedErrorMessageContext } from '../../hooks/useUnauthorizedErrorMessageContext'
import { normalizeError } from 'utils/commonUtils'
import { PasswordField } from 'components/PasswordField/PasswordField'
import { BasePasswordValidation } from 'components/ChangePasswordForm'
import styles from './ResetPasswordPage.module.scss'

type FormData = {
  password: string
  passwordConfirmation: string
}
type OnSubmit = (values: FormData) => void

export const ResetPasswordPage = (): JSX.Element => {
  const { uid } = useParams<{ uid: string }>()
  const [authorizing, setAuthorizing] = useState(true)
  const [invalidResponse, setInvalidResponse] = useState(false)
  const [token, setToken] = useState<string>('')
  const history = useHistory()

  const { setErrorMessage } = useUnauthorizedErrorMessageContext()

  const changeUserPassword = async (newPassword: string, token: string) => {
    try {
      await changePassword({
        newPassword,
        token,
      })
      history.push(UNAUTHORIZED_ROUTES.resetPasswordSuccess)
    } catch (e) {
      setErrorMessage(normalizeError(e).message)
    }
  }

  const onSubmit: OnSubmit = (values: FormData) => {
    void changeUserPassword(values.password, token)
  }

  useEffect(() => {
    const authorizePasswordChangeToken = async (uid: string) => {
      try {
        const { error, result, token } = await authorizePasswordChange(uid)
        setErrorMessage(error ?? null)
        if (result === 'Success') {
          return token
        } else {
          setErrorMessage(error ?? null)
        }
      } catch (e) {
        setErrorMessage(normalizeError(e).message)
        setInvalidResponse(true)
      }
    }

    const authorize = async () => {
      try {
        const token = await authorizePasswordChangeToken(uid)
        setAuthorizing(false)
        token && setToken(token)
      } catch (e) {}
    }
    void authorize()
  }, [setErrorMessage, uid])

  return (
    <>
      {authorizing ? (
        <Loader />
      ) : (
        <>
          {!invalidResponse && (
            <div className={styles.FormWrapper}>
              <Formik
                initialValues={{ password: '', passwordConfirmation: '' }}
                onSubmit={onSubmit}
                validationSchema={ResetPasswordValidation}
              >
                {({ handleSubmit, setFieldValue }) => {
                  return (
                    <Form className={styles.Form} onSubmit={handleSubmit}>
                      <h2 className={styles.PromptHeading}>Reset Password</h2>
                      <PasswordField
                        className="mb-3"
                        label="New Password"
                        name="password"
                        withRating
                        withSuggestion
                        onSelectSuggestion={suggestion =>
                          void setFieldValue('password', suggestion)
                        }
                      />
                      <PasswordField
                        className="mb-3"
                        label="Re-enter Password"
                        name="passwordConfirmation"
                      />

                      <Row className={styles.RightAlignedRow}>
                        <Button type="submit" className="w-100">
                          Reset
                        </Button>
                      </Row>
                    </Form>
                  )
                }}
              </Formik>
            </div>
          )}
        </>
      )}
    </>
  )
}

const ResetPasswordValidation = BasePasswordValidation(false)
