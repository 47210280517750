import React, { Component, ErrorInfo } from 'react'
import { Link } from 'react-router-dom'
import Row from 'react-bootstrap/Row'

import { ExclamationIcon } from 'components/ExclamationIcon'
import { Page } from 'components/Page'

import styles from './ErrorBoundary.module.scss'

interface Props {
  children: React.ReactNode
}

interface State {
  hasError: boolean
}

// Credits: https://react-typescript-cheatsheet.netlify.app/docs/basic/getting-started/error_boundaries/
export class ErrorBoundary extends Component<Props, State> {
  state = { hasError: false }

  static getDerivedStateFromError(): State {
    // Update state so the next render will show the fallback UI.
    return { hasError: true }
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo): void {
    // eslint-disable-next-line no-console
    console.error('Uncaught error:', error, errorInfo)
  }

  render(): React.ReactNode {
    if (this.state.hasError) {
      return (
        <Page hideHeader>
          <div className={styles.ExclamationIconContainer}>
            <ExclamationIcon />
          </div>
          <p className={styles.ErrorText}>Oops! Something went wrong.</p>
          <Row className="justify-content-center mb-3">
            <Link to="/" onClick={() => this.setState({ hasError: false })}>
              Go Home
            </Link>
          </Row>
        </Page>
      )
    }

    return this.props.children
  }
}
