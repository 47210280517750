import {
  ApiCompany,
  BaseApiResult,
  CompanyInfo,
  Perspective,
  WeightUOM,
  Program,
  User,
} from 'types'
import { api } from 'lib/api/base'
import { ENDPOINTS } from 'lib/api/endpoints'

export type UserResponse = BaseApiResult & {
  brand_id: number
  cell_phone: string
  country: string
  culture_id: number
  default_destination_id: number
  email: string
  first_name: string
  last_name: string
  perspective_id: number
  phone: string
  programs: Program[]
  send_notification_email: 0 | 1
  send_notification_text: 0 | 1
  user_name: string
  weight_uom: WeightUOM
}

type GetUser = (params?: { userId?: string }) => Promise<User>
export const getUser: GetUser = async (params = {}) => {
  const { userId } = params
  const { data: user } = await api.get<UserResponse>(ENDPOINTS.user, {
    params: {
      action: 'get',
      user_id: userId,
    },
  })

  return {
    brandId: user.brand_id,
    cellPhone: user.cell_phone,
    country: user.country,
    cultureId: user.culture_id,
    defaultDestinationId: user.default_destination_id,
    email: user.email,
    firstName: user.first_name,
    lastName: user.last_name,
    perspectiveId: user.perspective_id as Perspective,
    phone: user.phone,
    programs: user.programs,
    sendNotificationEmail: Boolean(user.send_notification_email),
    sendNotificationText: Boolean(user.send_notification_text),
    userName: user.user_name,
    weightUom: user.weight_uom,
  }
}

export const mapApiToUiCompany = (company: ApiCompany): CompanyInfo => {
  return {
    address1: company.address.address1 ?? '',
    address2: company.address.address2 ?? '',
    cellPhone: company.contact.cell_phone ?? '',
    city: company.address.city ?? '',
    country: company.address.country ?? '',
    email: company.contact.email ?? '',
    firstName: company.contact.first_name ?? '',
    lastName: company.contact.last_name ?? '',
    name: company.company ?? '',
    phone: company.contact.phone ?? '',
    postalCode: company.address.postal_code ?? '',
    stateProvince: company.address.state_province ?? '',
  }
}
