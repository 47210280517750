import { FC, useEffect, useState } from 'react'

import { activeSessionCookie, ContextProvider } from '../ContextProvider'
import { authorizationCheck } from '../../lib/api/user'
import { CurrentUser, SetErrorMessage } from '../../types'
import { DialogBox } from '../DialogBox'
import { ErrorMessageDialogBox } from '../ErrorMessageDialogBox'
import { ErrorMessageContextProvider } from '../ErrorMessageContextProvider'
import { getLocalToken, removeLocalToken, setLocalToken } from '../../utils/localToken'
import { report as reportError } from '../../lib/api/error'
import { Router } from '../Router'
import { StaticDataProvider } from 'contexts/StaticDataContext'
import { UnauthenticatedErrorMessageContextProvider } from '../UnauthorizedErrorMessageContextProvider'
import { useRefresh } from '../../hooks/useRefresh'
import { usePolling } from '../../hooks/usePolling'
import { useForceUpdate } from '../../hooks/useForceUpdate'
import { ThemeProvider } from 'contexts/ThemeContext'
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import GuestRedirectPage from 'pages/OrderPages/pages/GuestRedirectPage'
import { GuestOrderProvider } from 'state/context/GuestOrderContext'

export const App: FC = () => {
  const [user, setUser] = useState<CurrentUser | undefined>()
  const [initializing, setInitializing] = useState(true)

  const [errorMessage, setErrorMessage] = useState<string | null>(null)
  const [unAuthorizedErrorMessage, setUnauthorizedErrorMessage] = useState<string | null>(null)

  const [pollingState, setPollingState] = useState({
    alertCount: 0,
    version: '',
  })

  const { alertCount, version } = pollingState
  const [dialogVisible, setDialogVisible] = useState(false)
  const [refreshAlertTimestamp, refreshAlertCount] = useRefresh()

  usePolling(refreshAlertTimestamp, setPollingState, user)
  useForceUpdate(version, () => setDialogVisible(true))

  const displayAndReportErrorMessage: SetErrorMessage = (errorMessage, options) => {
    if (errorMessage) {
      void reportError({ errorMessage, ...options })
    }
    setErrorMessage(errorMessage)
  }

  useEffect(() => {
    if (!document.cookie.includes(`${activeSessionCookie}=1`)) {
      removeLocalToken()
    }

    const token = getLocalToken()
    if (token === null) {
      setInitializing(false)
      return
    }

    authorizationCheck({ token })
      .then(setUser)
      .catch(() => null)
      .finally(() => {
        setInitializing(false)
      })
  }, [setUser])

  useEffect(() => {
    if (user) {
      setLocalToken(user.token)
      document.cookie = `${activeSessionCookie}=1; path=/; SameSite=Strict`
    } else {
      removeLocalToken()
      document.cookie = `${activeSessionCookie}=0; max-age=0; path=/; SameSite=Strict`
    }
  }, [user])
  return (
    <BrowserRouter basename={import.meta.env.BASE_URL}>
      <GuestOrderProvider>
        <Switch>
          <Route exact path="/:brand_path/guest" render={() => <GuestRedirectPage />} />

          <Route
            path="/:brand_path?"
            render={() => (
              <ErrorMessageContextProvider
                errorMessage={errorMessage}
                setErrorMessage={displayAndReportErrorMessage}
              >
                <ContextProvider
                  initializing={initializing}
                  alertCount={alertCount}
                  refreshAlertCount={refreshAlertCount}
                  setUser={setUser}
                  user={user}
                >
                  <StaticDataProvider>
                    <ThemeProvider>
                      <UnauthenticatedErrorMessageContextProvider
                        errorMessage={unAuthorizedErrorMessage}
                        setErrorMessage={setUnauthorizedErrorMessage}
                      >
                        <Router />
                        <ErrorMessageDialogBox />
                        <DialogBox
                          buttonText="OK"
                          hide={() => window.location.reload()}
                          show={dialogVisible}
                          title="Application Update"
                        >
                          <p>
                            A new version of this app is now available. Please press OK to refresh
                            the application.
                          </p>
                          <p>
                            If you are in the middle of making changes, please note that you will
                            need to re-enter your changes.
                          </p>
                        </DialogBox>
                      </UnauthenticatedErrorMessageContextProvider>
                    </ThemeProvider>
                  </StaticDataProvider>
                </ContextProvider>
              </ErrorMessageContextProvider>
            )}
          />
        </Switch>
      </GuestOrderProvider>
    </BrowserRouter>
  )
}
