import { useRef, useState } from 'react'
import { Field, FieldProps } from 'formik'
import classNames from 'classnames'

import { PasswordRating } from './PasswordRating'
import PasswordHideIcon from 'images/password-hide.png'
import PasswordShowIcon from 'images/password-show.png'
import IdeaIcon from 'images/idea_icon.png'
import styles from './PasswordField.module.scss'
import { useModalState } from 'hooks/useModalState'
import { PasswordSuggestionModal } from './PasswordSuggestionModal'

type TPasswordFieldProps = {
  autoComplete?: string
  className?: string
  description?: string
  disabled?: boolean
  hideLabel?: boolean
  label: string
  name: string
  onSelectSuggestion?: (value: string) => void
  withRating?: boolean
  withSuggestion?: boolean
}

export const PasswordField = ({
  autoComplete,
  className,
  label,
  name,
  description,
  disabled,
  hideLabel,
  onSelectSuggestion,
  withRating,
  withSuggestion,
}: TPasswordFieldProps): JSX.Element => {
  const ref = useRef<HTMLInputElement>(null)
  const [inputType, setInputType] = useState<string>('password')
  const [ratingError, setRatingError] = useState<string>('')
  const {
    state: pwdSuggestionState,
    open: openPwdSuggestionModal,
    hide: hidePwdSuggestionModal,
  } = useModalState()

  const isPasswordType = inputType === 'password'

  const togglePasswordVisibility = () => {
    setInputType(oldInputType => (oldInputType === 'text' ? 'password' : 'text'))
    if (ref.current) {
      ref.current.focus()
    }
  }

  const onRatingError = (ratingError: string): void => {
    setRatingError(ratingError)
  }

  return (
    <>
      <Field name={name}>
        {({ field, meta }: FieldProps) => {
          return (
            <div className={className}>
              <div className={styles.PasswordFieldContainer}>
                <div className={styles.PasswordInputContainer}>
                  <input
                    {...field}
                    autoComplete={autoComplete}
                    autoCorrect="off"
                    className={classNames(styles.Input, {
                      [styles.Invalid]: !!(meta.error || ratingError),
                    })}
                    disabled={disabled}
                    id={name}
                    placeholder={label}
                    type={inputType}
                    ref={ref}
                  />
                  <div className={styles.Notch}>
                    <div className={styles.NotchBefore}></div>
                    <div
                      className={classNames(styles.NotchBetween, {
                        [styles.LabelHidden]: hideLabel,
                      })}
                    >
                      <label htmlFor={name}>{label}</label>
                    </div>
                    <div className={styles.NotchAfter}>
                      <button
                        type="button" // otherwise click submits the form by default
                        className={styles.PasswordIconButton}
                        onClick={togglePasswordVisibility}
                        disabled={disabled}
                      >
                        <img
                          src={isPasswordType ? PasswordShowIcon : PasswordHideIcon}
                          alt={`Click to ${isPasswordType ? 'show' : 'hide'} password`}
                        />
                      </button>
                    </div>
                  </div>
                </div>

                {withSuggestion && (
                  <button
                    type="button" // otherwise click submits the form by default
                    className={styles.PasswordSuggestionButton}
                    onClick={() => openPwdSuggestionModal()}
                    disabled={disabled}
                    title="Suggest strong passwords"
                  >
                    <img src={IdeaIcon} alt="Click to suggest strong passwords" />
                  </button>
                )}
              </div>

              {withRating && field.value ? (
                <PasswordRating password={field.value} onError={onRatingError} />
              ) : null}

              {description && <div className={styles.FieldDescription}>{description}</div>}

              {meta.error && <div className={styles.ErrorText}>{meta.error}</div>}
            </div>
          )
        }}
      </Field>

      {withSuggestion && onSelectSuggestion ? (
        <PasswordSuggestionModal
          hide={hidePwdSuggestionModal}
          {...pwdSuggestionState}
          onPasswordSelect={onSelectSuggestion}
        />
      ) : null}
    </>
  )
}
