import { useEffect, useState } from 'react'
import { AxiosError } from 'axios'
import { useDebouncedCallback } from 'use-debounce'

import { BaseApiResult } from 'types'
import { isApiError, normalizeError } from 'utils/commonUtils'
import { noAuthApi } from 'lib/api/base'
import { ENDPOINTS } from 'lib/api/endpoints'

import styles from './PasswordRating.module.scss'

const strengthLevels = [0, 1, 2, 3, 4]
const ratingCheckDelay = 800 // ms to wait before issuing a rating check API call

type TPasswordRatingProps = {
  onError?: (error: string) => void
  password: string
}

type TCheckPasswordResponse = BaseApiResult & {
  rating: string
  strength: number
}

export const PasswordRating = ({ onError, password }: TPasswordRatingProps): JSX.Element => {
  const [strength, setStrength] = useState<number>(0)
  const [rating, setRating] = useState<string>('')
  const [validatingError, setValidatingError] = useState<string>('')
  const [validating, setValidating] = useState<boolean>(false)

  const onPasswordChangeDebounced = useDebouncedCallback(async (password: string) => {
    // NOTE: check_password API combines 2 actions: password rating calculation
    // AND policy validation. The following cases should be taken into account:
    // - 'result = Failure' - indicates either a failure in request processing or
    //   a failure of a password policy.
    // - 'result = Success' always indicates that password policy passed.
    //   Keep in mind that password can pass policy rules but can still be
    //   considered weak or terrrible.
    let data: TCheckPasswordResponse | null = null
    try {
      setStrength(0)
      setRating('')
      setValidatingError('')
      setValidating(true)

      const { data: response } = await noAuthApi.get<TCheckPasswordResponse>(ENDPOINTS.user, {
        params: {
          action: 'check_password',
          password,
        },
      })

      data = response
    } catch (e) {
      if (isApiError(e)) {
        const result = e as AxiosError<TCheckPasswordResponse>
        data = result.response?.data ?? null
      } else {
        throw new Error(normalizeError(e).message)
      }
    } finally {
      setStrength(data?.strength ?? 0)
      setRating(data?.rating ?? '')
      setValidatingError(data?.error ?? '')
      setValidating(false)

      if (onError) {
        onError(data?.error ?? '')
      }
    }
  }, ratingCheckDelay)

  useEffect(() => {
    if (password) {
      void onPasswordChangeDebounced(password)
    }
  }, [onPasswordChangeDebounced, password])

  return (
    <div className={styles.PasswordRatingContainer}>
      {validating ? (
        <>
          <span className={styles.Loader}></span>
          <div className={styles.RatingDescription}>Checking password ...</div>
        </>
      ) : validatingError ? (
        <div className={styles.ErrorText}>{validatingError}</div>
      ) : rating ? (
        <>
          <div className={styles.StrengthBar}>
            {strengthLevels.map(level => {
              return (
                <span
                  key={level}
                  className={level <= strength ? styles[`Level-${strength}`] : ''}
                ></span>
              )
            })}
          </div>

          <div className={`${styles.RatingDescription} ${styles[`Level-${strength}`]}`}>
            Password is {rating.toLowerCase()}
          </div>
        </>
      ) : null}
    </div>
  )
}
