import { camelCaseKeys } from 'utils/camelCaseKeys'
import { MenuConfig } from 'types'

import { MenuConfigResponse } from '../formatUserResponse'

const mapAndReportSubMenuItems = ({ menu, ...menuItem }: MenuConfig): MenuConfig => {
  if (!menu) return menuItem
  return {
    ...menuItem,
    menu,
  }
}

type FilterMenuItems = (params: MenuConfigResponse[]) => MenuConfig[]
export const filterMenuItems: FilterMenuItems = menuConfigResponse => {
  const responseWithPluralizedGrids = menuConfigResponse.map(({ grid, ...menu }) => {
    const menuWithPluralizedGrids = {
      ...menu,
      grids: grid,
    }

    if (menuWithPluralizedGrids.menu) {
      menuWithPluralizedGrids.menu = menuWithPluralizedGrids.menu.map(({ grid, ...menu }) => ({
        ...menu,
        grids: grid,
      }))
    }

    return menuWithPluralizedGrids
  })

  return camelCaseKeys<MenuConfigResponse[], MenuConfig[]>(responseWithPluralizedGrids, {
    deep: true,
  }).map(menuItem => mapAndReportSubMenuItems(menuItem))
}
