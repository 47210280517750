import * as React from 'react'
import { useHistory } from 'react-router-dom'

import { ChangePasswordForm } from 'components/ChangePasswordForm'

import * as helpers from './helpers'
import { useUnauthorizedErrorMessageContext } from '../../hooks/useUnauthorizedErrorMessageContext'

export const EXPIRED_PASSWORD = 'Your password has expired'

export const ExpiredPasswordForm = (): JSX.Element => {
  const history = useHistory()

  const { setErrorMessage } = useUnauthorizedErrorMessageContext()

  React.useEffect(() => {
    setErrorMessage(EXPIRED_PASSWORD)
  }, [setErrorMessage])

  return (
    <ChangePasswordForm
      initialValues={{ oldPassword: '', password: '', passwordConfirmation: '' }}
      onSubmit={values => helpers.handleSubmit(values, history, setErrorMessage)}
    />
  )
}
