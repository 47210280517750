import * as React from 'react'
import { getIn, useFormikContext } from 'formik'

export const FocusFirstErroredField = (): null => {
  const { errors, isSubmitting, isValidating } = useFormikContext()

  React.useEffect(() => {
    if (isSubmitting && !isValidating) {
      const formFields = document.querySelectorAll<HTMLElement>('input, select, textarea')

      for (let i = 0; i < formFields.length; i++) {
        const fieldName = formFields[i].getAttribute('name') ?? ''
        const error = getIn(errors, fieldName)

        if (error) {
          formFields[i].focus()
          break
        }
      }
    }
  }, [errors, isSubmitting, isValidating])

  return null
}
