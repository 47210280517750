import { FC, useCallback } from 'react'
import classNames from 'classnames'
import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'
import { Redirect } from 'react-router'
import Row from 'react-bootstrap/Row'
import { useHistory } from 'react-router-dom'

import { ClientError } from 'lib/api/ClientError'
import { FormValues, LogInForm } from 'forms/LogInForm'
import { logIn } from 'lib/api/user'

import { getDefaultPath } from '../../utils/getDefaultPath'
import { Page } from '../Page'
import { UNAUTHORIZED_ROUTES } from '../../Routes'
import { useAppContext } from '../../hooks/useAppContext'
import { useErrorMessageContext } from '../../hooks/useErrorMessageContext'

import styles from './LoginPage.module.scss'
import { useTheme } from 'contexts/ThemeContext'
import { loginPath } from 'components/Router/Routes'

export const LogInPage: FC = () => {
  const { setUser, user } = useAppContext()
  const { setErrorMessage } = useErrorMessageContext()
  const { loginImage } = useTheme()

  const history = useHistory<{ errorMessage: string; referrer: string }>()
  const errorMessage = history.location.state?.errorMessage

  const onSubmit = useCallback(
    async (values: FormValues) => {
      setErrorMessage('')

      try {
        const user = await logIn(values)

        if (user) {
          const state = history.location.state
          setUser(user)

          history.push(state?.referrer ?? getDefaultPath(user))
        }
      } catch (error) {
        if (error instanceof ClientError) {
          setErrorMessage(error.message)
        } else {
          throw error
        }
      }
    },
    [history, setErrorMessage, setUser],
  )

  if (user?.passwordExpired) {
    return <Redirect to={UNAUTHORIZED_ROUTES.passwordExpired} />
  }

  if (user) {
    return <Redirect to={getDefaultPath(user)} />
  }

  return (
    <Page>
      <Container fluid className={classNames(styles.PageContainer, 'flex-grow-1')}>
        <Row className="justify-content-center align-content-center h-100">
          {window.location.pathname === loginPath || (loginImage && loginImage !== '') ? (
            <Col
              lg={5}
              xl={6}
              className="d-sm-none d-none d-lg-flex align-items-center justify-content-lg-end"
            >
              <img
                className={classNames(styles.Image, 'img-fluid')}
                alt="Sign in"
                src={loginImage}
              />
            </Col>
          ) : null}
          <Col sm={12} lg={6} className="d-flex justify-content-sm-center justify-content-lg-start">
            <div className={styles.Container}>
              <LogInForm
                errorMessage={errorMessage}
                showCreateNewAccount
                showForgotPassword
                onSubmit={onSubmit}
                showStartNewReturn
              />
            </div>
          </Col>
        </Row>
      </Container>
    </Page>
  )
}
