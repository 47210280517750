import { api } from 'lib/api/base'
import { GUEST_ENDPOINTS } from 'lib/api/endpoints'
import { GuestPageResponse } from 'lib/api/types'

export const getPageForToken = async (token: string) => {
  const { data } = await api.get<GuestPageResponse>(GUEST_ENDPOINTS.pageForToken, {
    params: { action: 'get_by_token', token },
  })

  return data
}
