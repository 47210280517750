import { Field, FieldProps } from 'formik'

import { SelectOption } from 'types'
import { NativeSelect } from 'components/Select/NativeSelect'

type TSelectFieldProps = {
  ariaLabel?: string
  className?: string
  disabled?: boolean
  id?: string
  label?: string
  name: string
  onChange?: (v: number | string) => void
  options: SelectOption[]
  whiteBG?: boolean
}

export const SelectField = ({
  ariaLabel,
  className,
  disabled,
  id,
  label,
  name,
  onChange,
  options,
  whiteBG = false,
}: TSelectFieldProps): JSX.Element => (
  <Field name={name}>
    {({ field, form, meta }: FieldProps) => {
      return (
        <NativeSelect
          ariaLabel={ariaLabel}
          className={className}
          disabled={disabled}
          error={meta.touched && form.submitCount > 0 ? meta.error : undefined}
          id={id}
          name={field.name}
          label={label}
          onBlur={field.onBlur}
          onChange={e => {
            field.onChange(e)
            if (onChange) {
              onChange(e.target.value)
            }
          }}
          options={options}
          value={field.value}
          whiteBG={whiteBG}
        />
      )
    }}
  </Field>
)
