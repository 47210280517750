import { FC } from 'react'
import styles from './ProgressBar.module.scss'
import classNames from 'classnames'

interface Props {
  className?: string
  progressPercentage: number
}

export const ProgressBar: FC<Props> = ({ className, progressPercentage }) => {
  const atMaxPercentage = progressPercentage === 100

  return (
    <div className={classNames(styles.ProgressBarContainer, className)}>
      <div className={styles.ProgressBar} style={{ width: `${progressPercentage}%` }}>
        <span className={styles.CurrentPercentageText}>{progressPercentage}%</span>
      </div>
      {!atMaxPercentage && <span className={styles.MaxPercentageText}>100%</span>}
    </div>
  )
}
