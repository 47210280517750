/** If any of these routes are modified, the unbrandedRoutes array in /src/routes.ts
 * must also be modified. This is for comparing the url and checking
 * if the url parameter is a brand or path
 */

export const createAccount = '/create_account'
export const dashboardPath = '/dashboard'
export const destinationRoutingsPath = '/destination_routings'
export const destinationsPath = '/destinations'
export const dispatchSearchPath = '/dispatch_search'
export const editOrderPath = (id?: string): string =>
  `${orderPath}/${id ? encodeURIComponent(id) : ':orderId(\\d+)'}`
export const editOrderParam = '/:orderId(\\d+)?'
export const emailPasswordResetPath = '/email_password_reset'
export const loginPath = '/sign_in'
export const myAccountPath = '/account'
export const orderDetailsPath = (id?: string): string =>
  `/order_details/${id ? encodeURIComponent(id) : ':jobId'}`
export const orderPath = '/order'
export const quoteDetailsPath = (id?: string): string =>
  `/draft_details/${id ? encodeURIComponent(id) : ':quoteId'}`
export const reportQueuePath = '/report_queue'
export const resetPasswordSuccessPath = '/reset_password_success'
export const rootPath = '/'
export const signOutPath = '/sign_out'
export const userAdminPath = '/user_admin'
export const toolsPath = '/tools'
export const viewsPath = '/views'
export const reportSchedulePath = '/report_schedule'
export const reportSubmissionPath = '/report_submission'
export const reportsPath = '/reports/'
export const allOrdersReportPath = '/reports/all_orders_report/'
export const reportGridPath = '/reports/:configId'
export const uploadsPath = '/uploads'
export const programsPath = '/programs'
export const programPath = '/program/:id'
export const companyProfile = '/company'
