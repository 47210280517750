import { useEffect, useState, createContext, useCallback, useContext, useMemo } from 'react'

import { BrandParameter } from 'types'
import { useAppContext } from 'hooks/useAppContext'
import { getSettingsForPath } from 'lib/api/setting'
import { hexToCssHsl } from 'utils/cssHexToHSL'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import { routesForApp } from '../../Routes'
import { MixpanelEvents, mixpanelTrack } from 'lib/api/mixpanel/track'
import { getURL } from 'utils/getURL'

import SignInIllustration from 'images/SignIn-Illustration.svg'
import Logo from 'images/ReturnCenterLogo.svg'
import MobileLogo from 'images/ReturnCenterLogoMobile.svg'

type ContextValue = {
  brandPath: string | undefined
  cleanup: () => void
  loginImage: string
  logo: string
  mobileLogo: string
  paradigmId: number | null
  promoCode: string | null
  promoCodeId: number | null
  setParadigmId: React.Dispatch<React.SetStateAction<number | null>>
  setPromoCode: React.Dispatch<React.SetStateAction<string | null>>
  setPromoCodeId: React.Dispatch<React.SetStateAction<number | null>>
  theme?: BrandParameter
}

const ThemeContext = createContext<ContextValue | undefined>(undefined)

type StaticDataProviderProps = {
  children: React.ReactNode
}

const ThemeProvider = ({ children }: StaticDataProviderProps): JSX.Element => {
  const { user } = useAppContext()
  const { brand_path } = useParams<{ brand_path?: string }>()
  const [brandPath, setBrandPath] = useState(() =>
    brand_path && !routesForApp.includes(`/${brand_path}`) ? brand_path : undefined,
  )
  const { pathname } = useLocation()
  const history = useHistory()
  const [theme, setTheme] = useState<BrandParameter>()
  const [logo, setLogo] = useState(Logo)
  const [mobileLogo, setMobileLogo] = useState(MobileLogo)
  const [loginImage, setLoginImage] = useState(SignInIllustration)
  const [loadingStyles, setLoadingStyles] = useState<boolean>(!!brandPath)
  const [promoCode, setPromoCode] = useState<string | null>(null)
  const [promoCodeId, setPromoCodeId] = useState<number | null>(null)
  const [paradigmId, setParadigmId] = useState<number | null>(null)

  const cleanup = useCallback(() => {
    sessionStorage.removeItem('isSingleKit')
    sessionStorage.removeItem('wasRedirectedToCreateAccount')

    setBrandPath(undefined)
    setPromoCode(null)
    setPromoCodeId(null)
    setParadigmId(null)
  }, [])

  const setDefaultTheme = () => {
    setLogo(Logo)
    setMobileLogo(MobileLogo)
    setLoginImage(SignInIllustration)

    /** Setting font and font color are currently unused */
    /** Login image is set on the login component */
    document.documentElement.style.setProperty('--color-navbar', '#fff')
    document.documentElement.style.setProperty('--color-primary-h', '191')
    document.documentElement.style.setProperty('--color-primary-s', '97%')
    document.documentElement.style.setProperty('--color-primary-l', '41%')
  }

  const updateThemeState = (theme: BrandParameter) => {
    setTheme(theme)
    if (theme.logo) {
      const logoURL = getURL(theme.logo)
      setLogo(logoURL)
      setMobileLogo(logoURL)
    }
    if (theme.loginImage) {
      setLoginImage(getURL(theme.loginImage))
    }
    if (theme.navigationBarColor) {
      document.documentElement.style.setProperty('--color-navbar', theme.navigationBarColor)
    }
    if (theme.primaryColor) {
      const hslValues = hexToCssHsl(theme.primaryColor)
      if (hslValues) {
        document.documentElement.style.setProperty('--color-primary-h', hslValues.h)
        document.documentElement.style.setProperty('--color-primary-s', hslValues.s)
        document.documentElement.style.setProperty('--color-primary-l', hslValues.l)
      }
    }
  }

  useEffect(() => {
    if (brandPath) {
      setLoadingStyles(true)
      void getSettingsForPath(`/${brandPath}`)
        .then(settings => {
          if (settings.type === 'theme') updateThemeState(settings)

          if (settings.type === 'promo') {
            setPromoCode(settings.promoCode)
            setPromoCodeId(settings.promoCodeId)
            setParadigmId(settings.paradigmId)
            if (settings.singleKit) sessionStorage.setItem('isSingleKit', 'true')

            const newLocation = pathname.replace(`/${brandPath}`, '')
            history.replace(newLocation)
            setBrandPath(undefined)
          }
        })
        .catch(() => setBrandPath(undefined))
        .finally(() => setLoadingStyles(false))
    } else if (user?.brandingParameters) {
      updateThemeState(user.brandingParameters)
    } else {
      setDefaultTheme()
    }
  }, [brandPath, user?.brandingParameters, history, pathname])

  useEffect(() => {
    if (promoCode) {
      const trackVisit = async () => {
        await mixpanelTrack(MixpanelEvents.PROMO_LINK_VISITED, {
          promoCode,
        })
      }

      void trackVisit()
    }
  }, [promoCode])

  const value = useMemo<ContextValue>(
    () => ({
      brandPath,
      cleanup,
      loginImage,
      logo,
      mobileLogo,
      paradigmId,
      promoCode,
      promoCodeId,
      setParadigmId,
      setPromoCode,
      setPromoCodeId,
      theme,
    }),
    [brandPath, cleanup, loginImage, logo, mobileLogo, paradigmId, promoCode, promoCodeId, theme],
  )

  if (loadingStyles) return <></>

  return <ThemeContext.Provider value={value}>{children}</ThemeContext.Provider>
}

const useTheme = (): ContextValue => {
  const context = useContext(ThemeContext)

  if (context === undefined) {
    throw new Error('useTheme must be used within an ThemeProvider')
  }

  return context
}

export { ThemeProvider, useTheme }
