import React, { FC, useCallback, useRef, useState } from 'react'
import classnames from 'classnames'
import { Link } from 'react-router-dom'
import { Overlay, Tooltip } from 'react-bootstrap'

import { MenuConfig } from 'types'

import styles from './HeaderDropdown.module.scss'
import './HeaderDropdown.scss'

export type Props = {
  label: string
  menu: MenuConfig[]
}
export const HeaderDropdown: FC<Props> = ({ label, menu }) => {
  const [show, setShow] = useState(false)
  const hide = useCallback(() => setShow(false), [])
  const target = useRef(null)

  const buttonClassName = classnames('dropdown-toggle', 'nav-link', styles.Button)
  const dropdownItemClassName = classnames('dropdown-item', styles.DropdownItem)
  const menuItems = React.useMemo(
    () =>
      menu.map(({ path, description }) => (
        <Link key={path} to={path} className={dropdownItemClassName} onClick={hide}>
          {description}
        </Link>
      )),
    [dropdownItemClassName, menu, hide],
  )

  if (menuItems.length === 0) {
    return null
  }

  return (
    <>
      <button className={buttonClassName} ref={target} onClick={() => setShow(visible => !visible)}>
        <span>{label}</span>
      </button>
      <div className={classnames(styles.SubMenu, { [styles.Open]: show })}>{menuItems}</div>
      <Overlay onHide={hide} placement="bottom" rootClose show={show} target={target.current}>
        <Tooltip id="global-tools-tooltip">{menuItems}</Tooltip>
      </Overlay>
    </>
  )
}
