export const buildMemoryStore = <T>(
  maxItems = 50,
): {
  delete: (key: string) => Promise<boolean>
  get: (key: string) => Promise<T | undefined>
  set: (key: string, value: T) => Promise<void>
} => {
  if (maxItems <= 0) {
    throw new Error('maxItems must be > 0')
  }

  const map = new Map<string, T>()

  const deleteKey = (key: string) => {
    return Promise.resolve(map.delete(key))
  }

  const get = (key: string) => {
    return Promise.resolve(map.get(key))
  }

  const set = (key: string, value: T) => {
    map.set(key, value)
    if (map.size > maxItems) {
      map.delete(map.keys().next().value as string)
    }
    return Promise.resolve()
  }

  return { delete: deleteKey, get, set }
}
