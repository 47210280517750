import { JSX } from 'react'
import styles from './FormHeader.module.scss'
import classNames from 'classnames'

type Props = {
  noUnderline?: boolean
  text: string
}

const FormHeader = ({ noUnderline, text }: Props): JSX.Element => {
  return (
    <h2 className={classNames(styles.FormHeader, { [styles.NoUnderline]: noUnderline })}>{text}</h2>
  )
}

export default FormHeader
