import * as React from 'react'
import Container from 'react-bootstrap/Container'
import { Helmet } from 'react-helmet'

import { Header } from 'components/Header'

import { Loader } from 'components/Loader'
import styles from './WizardPage.module.scss'
import { ProgressBar } from 'components/ProgressBar/ProgressBar'
import { useGuestOrderContext } from 'hooks/useGuestOrderContext'

export type Props = {
  children?: React.ReactNode
  description?: string
  fitOnScreen?: boolean
  headerActions?: React.ReactNode
  hideFooter?: boolean
  hideHeader?: boolean
  isLoading?: boolean
  title?: string
}

export const WizardPage = ({
  children,
  description = 'OnePak Partner Community',
  isLoading,
  title = 'ReturnCenter Community',
}: Props): JSX.Element => {
  const { guestPageExtraData } = useGuestOrderContext()
  const orderProgress = guestPageExtraData?.order_progress

  return (
    <div className={styles.Page}>
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={description} />
      </Helmet>

      <Header />

      <div className={styles.CenterLayout}>
        <div className={styles.ProgressBarContainer}>
          {orderProgress ? <ProgressBar progressPercentage={orderProgress} /> : null}
        </div>

        <Container className={styles.Container}>
          {isLoading ? (
            <div className={styles.LoaderContainer}>
              <Loader />
            </div>
          ) : (
            children
          )}
        </Container>
      </div>
    </div>
  )
}
