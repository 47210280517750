import { Formik } from 'formik'

import { Form } from 'components/Form'
import { FormContainer } from 'components/FormContainer'
import * as Forms from 'models/Forms'
import { TFormSubmit } from 'types'

import FormHeader from 'components/FormHeader/FormHeader'
import { SelectField } from 'components/SelectField'
import { TextField } from 'components/TextField'
import { Row } from 'react-bootstrap'
import styles from './GuestOutOfServiceAreaForm.module.scss'

export interface GuestOutOfServiceAreaFormValues {}

export type Props = {
  initialValues: GuestOutOfServiceAreaFormValues
  onSubmit: TFormSubmit<GuestOutOfServiceAreaFormValues, void>
  title?: string
}

export const GuestOutOfServiceAreaForm = ({
  initialValues,
  onSubmit,
  title,
}: Props): JSX.Element => {
  return (
    <Formik enableReinitialize initialValues={initialValues} onSubmit={onSubmit}>
      {({ errors, handleSubmit, isSubmitting, submitCount }) => (
        <Form onSubmit={handleSubmit}>
          <FormHeader text="Out of our pickup service area." noUnderline />

          <div className={styles.ExplanationText}>
            <p>
              Thank you for reaching out to us regarding your IT asset, pickup, data wipe, and
              recycling. While the country you’ve selected is currently outside of our pickup range,
              we’re still committed to helping you find the right solution.
            </p>
            <p>
              One of our global representatives is available to assist you in exploring alternative
              options, such as trusted partners or shipping arrangement, to ensure your IT assets
              are securely wiped and responsibly recycled.
            </p>
            <p>
              Please let us know the best way to connect you with a global rep, and we’ll work to
              provide a solution that fits your needs.
            </p>
          </div>

          <FormContainer
            submitButtonText="Contact Representative"
            formError={Forms.formikErrors({ errors, submitCount })}
            isSubmitting={isSubmitting}
            title={title}
            isVersion2
          >
            <Row className={styles.InputRow}>
              <TextField label="First Name" name="first_name" />
            </Row>
            <Row className={styles.InputRow}>
              <TextField label="Last Name" name="last_name" />
            </Row>
            <Row className={styles.InputRow}>
              <SelectField label="Country" name="county" options={[]} />
            </Row>
            <Row className={styles.InputRow}>
              <TextField label="Email" name="email" />
            </Row>
            <Row className={styles.InputRow}>
              <TextField label="Mobile (Optional)" name="phone" />
            </Row>
          </FormContainer>
        </Form>
      )}
    </Formik>
  )
}
