import * as React from 'react'
import BootstrapForm from 'react-bootstrap/Form'

import { FocusFirstErroredField } from './components/FocusFirstErroredField'

type Props = {
  children: React.ReactNode
  className?: string
  id?: string
  onSubmit?: React.FormEventHandler<HTMLFormElement>
}

/**
 * Wraps form fields in a Bootstrap form while providing functionality
 * for focusing the first errored input on an invalid form submission
 */
export const Form = ({ children, className, onSubmit, id }: Props): JSX.Element => {
  return (
    <BootstrapForm className={className} onSubmit={onSubmit} id={id}>
      <FocusFirstErroredField />

      {children}
    </BootstrapForm>
  )
}
