import { FC, PropsWithChildren, ReactNode } from 'react'
import BootstrapModal from 'react-bootstrap/Modal'
import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'

import { Button } from 'components/Button'

import styles from './Modal.module.scss'

type ModalProps = {
  dialogClassName?: string
  disabled?: boolean
  formId: string
  hide: () => void
  hideCancel?: boolean
  hideSubmit?: boolean
  justifyButtons?: 'start' | 'end' | 'center' | 'between' | 'around'
  saving?: boolean
  show?: boolean
  size?: 'sm' | 'lg' | 'xl'
  submitButtonText?: ReactNode
  title: ReactNode
}

export const Modal: FC<PropsWithChildren<ModalProps>> = ({
  children,
  dialogClassName,
  disabled = false,
  formId,
  hide,
  hideCancel,
  hideSubmit = false,
  justifyButtons = 'end',
  show,
  size,
  submitButtonText = 'Submit',
  title,
  saving = false,
}) => {
  return (
    <BootstrapModal
      centered
      dialogClassName={dialogClassName}
      onHide={hide}
      show={show}
      size={size}
    >
      <BootstrapModal.Header closeButton>
        <BootstrapModal.Title>{title}</BootstrapModal.Title>
      </BootstrapModal.Header>

      <BootstrapModal.Body className={styles.ModalBody}>
        <Container fluid>
          <Row>{children}</Row>
        </Container>
      </BootstrapModal.Body>

      <BootstrapModal.Footer className={styles.ModalFooter}>
        <Row className={`justify-content-${justifyButtons} w-100 mb-3`}>
          {hideCancel ? null : (
            <Col xs={12} md={size === 'xl' ? 3 : 4}>
              <Button className="me-4 w-100" onClick={hide} variant="outline-dark">
                Cancel
              </Button>
            </Col>
          )}
          {!hideSubmit && (
            <Col xs={12} md={size === 'xl' ? 3 : 5}>
              <Button
                className="w-100"
                disabled={disabled}
                loading={saving}
                formId={formId}
                onClick={() => {
                  if (!formId) {
                    const form = document.getElementById(formId)
                    const event = new CustomEvent('submit', { cancelable: true })
                    form?.dispatchEvent(event)
                  }
                }}
                type="submit"
              >
                {submitButtonText}
              </Button>
            </Col>
          )}
        </Row>
      </BootstrapModal.Footer>
    </BootstrapModal>
  )
}
