import { api } from 'lib/api/base'
import { ENDPOINTS } from 'lib/api/endpoints'
import { BaseApiResult, Country } from 'types'

export type GetCountriesResponse = BaseApiResult & {
  countries: Country[]
}

type GetCountries = () => Promise<Country[]>
export const getCountries: GetCountries = async () => {
  const {
    data: { countries },
  } = await api.get<GetCountriesResponse>(ENDPOINTS.setting, {
    params: {
      action: 'get_countries',
    },
  })

  return countries
}
