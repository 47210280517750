type FormikErrorProps = {
  errors: unknown
  submitCount: number
}

const formikErrors = ({ errors, submitCount }: FormikErrorProps): string => {
  if (submitCount > 0 && errors && typeof errors === 'string') {
    return errors
  } else {
    return ''
  }
}

export { formikErrors }
