/* eslint-disable @typescript-eslint/no-unsafe-return */
import { CurrentUser } from '../../types'
import { viewsPath } from '../../components/Router/Routes'

/**
 * Get the default path for the user. Defaults the the Views page if it doesn't exist
 * @param user - The current user if it exists
 */
export const getDefaultPath = (user?: Pick<CurrentUser, 'defaultPath'>): string => {
  return (user && user.defaultPath) || viewsPath
}
