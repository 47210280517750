import { FC, useCallback, useRef, useState } from 'react'
import classnames from 'classnames'
import { Link } from 'react-router-dom'
import { Overlay, Tooltip } from 'react-bootstrap'

import './UserAccountIcon.scss'
import { MenuConfig } from 'types'
import styles from './UserAccountIcon.module.scss'
import { TransparentButton } from 'components/TransparentButton'

type Props = {
  menu: MenuConfig[]
}
export const UserAccountIcon: FC<Props> = ({ menu }) => {
  const [show, setShow] = useState(false)
  const hide = useCallback(() => setShow(false), [])
  const target = useRef(null)

  const dropdownItemClassName = classnames('dropdown-item', styles.DropdownItem)

  const menuItems = menu.map(({ path, description }) => {
    return (
      <Link key={path} to={path} className={dropdownItemClassName}>
        {description}
      </Link>
    )
  })

  return (
    <>
      <div className={styles.UserIconWrapper} ref={target}>
        <TransparentButton
          className={styles.UserIcon}
          onClick={() => setShow(visible => !visible)}
        />
      </div>

      <Overlay onHide={hide} placement="bottom" rootClose show={show} target={target.current}>
        <Tooltip id="tooltip-user-account-icon">{menuItems}</Tooltip>
      </Overlay>
    </>
  )
}
