import { WizardPage } from 'components/WizardPage/WizardPage'
import { GuestOutOfServiceAreaForm } from 'forms/GuestOutOfServiceAreaForm/GuestOutOfServiceAreaForm'
import { JSX } from 'react'

const GuestOutOfServiceAreaPage = (): JSX.Element => {
  const onSubmit = () => Promise.resolve()

  return (
    <WizardPage>
      <GuestOutOfServiceAreaForm initialValues={{}} onSubmit={onSubmit} />
    </WizardPage>
  )
}

export default GuestOutOfServiceAreaPage
