export const hexToCssHsl = (hex: string) => {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)

  if (!result || !result[1] || !result[2] || !result[3]) return
  let r = parseInt(result[1], 16)
  let g = parseInt(result[2], 16)
  let b = parseInt(result[3], 16)

  ;(r /= 255), (g /= 255), (b /= 255)
  const max = Math.max(r, g, b),
    min = Math.min(r, g, b)
  let h,
    s,
    l = (max + min) / 2
  if (max === min) {
    h = s = 0 // achromatic
  } else {
    const d = max - min
    s = l > 0.5 ? d / (2 - max - min) : d / (max + min)
    switch (max) {
      case r:
        h = (g - b) / d + (g < b ? 6 : 0)
        break
      case g:
        h = (b - r) / d + 2
        break
      case b:
        if (h) h = (r - g) / d + 4
        break
    }
    if (h) {
      h /= 6
    }
  }
  if (h) h = Math.round(h * 360)
  s = Math.round(s * 100)
  l = Math.round(l * 100)

  type HSLVals = {
    h: string
    l: string
    s: string
  }

  const hslValues: HSLVals = { h: '191', l: '41%', s: '97%' }
  if (h && l && s) {
    hslValues['h'] = `${h}`
    hslValues['l'] = `${l}%`
    hslValues['s'] = `${s}%`
  }

  return hslValues
}
