import { useEffect, useRef } from 'react'
import { useLoggedInAppContext } from '../useLoggedInAppContext'

export const useCallbackWhenUserChanges = (callback: () => any): void => {
  const {
    user: { loggedInAt },
  } = useLoggedInAppContext()
  const localLoggedInAt = useRef(loggedInAt)

  useEffect(() => {
    if (loggedInAt !== localLoggedInAt.current) callback()

    localLoggedInAt.current = loggedInAt
  }, [callback, loggedInAt])
}
