import * as React from 'react'

import { resetCache } from 'lib/api/cacheAdapter'
import { useAppContext } from 'hooks/useAppContext'

export const SignOut = (): null => {
  const { setUser } = useAppContext()

  React.useEffect(() => {
    resetCache()
    setUser(undefined)
  }, [setUser])

  return null
}
