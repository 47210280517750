import { AxiosHeaders, AxiosRequestConfig, InternalAxiosRequestConfig } from 'axios'

import {
  isArrayBuffer,
  isArrayBufferView,
  isBlob,
  isFile,
  isFormData,
  isURLSearchParams,
} from './utils'

export const attachToken = (
  config: AxiosRequestConfig | InternalAxiosRequestConfig<any>,
  token: string,
): InternalAxiosRequestConfig<any> => {
  const method = config.method?.toLowerCase()
  if (method === 'get') {
    if (!(isURLSearchParams(config.params) || typeof config.params === 'string')) {
      config.params = {
        ...config.params,
        token,
      }
    }
  } else {
    if (isFormData(config.data)) {
      config.data.append('token', token)
    } else if (typeof config.data === 'string') {
      try {
        const data = JSON.parse(config.data)
        config.data = {
          ...data,
          token,
        }
      } catch {}
    } else if (
      !(
        isArrayBuffer(config.data) ||
        isArrayBufferView(config.data) ||
        isBlob(config.data) ||
        isFile(config.data) ||
        isURLSearchParams(config.data)
      )
    ) {
      config.data = {
        ...config.data,
        token,
      }
    }
  }

  if (!config.headers) config.headers = new AxiosHeaders()

  return config as InternalAxiosRequestConfig<any>
}
