import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import { Formik } from 'formik'
import Row from 'react-bootstrap/Row'
import * as Yup from 'yup'

import { TFormSubmit } from 'types'
import { PasswordField } from 'components/PasswordField/PasswordField'

import styles from './ChangePasswordForm.module.scss'

export type FormValues = {
  oldPassword: string
  password: string
  passwordConfirmation: string
}

export type ChangePasswordFormProps = {
  formId?: string
  hideSubmit?: boolean
  initialValues: FormValues
  onSubmit: TFormSubmit<FormValues, void>
}

export const ChangePasswordForm = ({
  formId = 'change-password',
  hideSubmit = false,
  initialValues,
  onSubmit,
}: ChangePasswordFormProps): JSX.Element => {
  return (
    <div className={styles.Container}>
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={BasePasswordValidation}
      >
        {({ handleSubmit, setFieldValue }) => {
          return (
            <Form id={formId} className={styles.Form} onSubmit={handleSubmit}>
              <PasswordField
                className="mb-3"
                label="Current Password"
                name="oldPassword"
                withRating={false}
              />
              <PasswordField
                className="mb-3"
                label="New Password"
                name="password"
                withRating
                withSuggestion
                onSelectSuggestion={suggestion => void setFieldValue('password', suggestion)}
              />
              <PasswordField
                className="mb-3"
                label="Re-enter Password"
                name="passwordConfirmation"
                withRating={false}
              />

              {!hideSubmit && (
                <Row className={styles.RightAlignedRow}>
                  <Button className={styles.Reset} type="submit">
                    Change Password
                  </Button>
                </Row>
              )}
            </Form>
          )
        }}
      </Formik>
    </div>
  )
}

export const MIN_PWD_LENGTH = 8

export const BasePasswordValidation = (with_old_password: boolean = true) => {
  let schema = Yup.object({
    // We use `check_password` API to get password rating. This API also
    // performs password policy check. To avoid logic and error duplication do
    // not add password policy validation rules here.
    password: Yup.string().required('Please enter new password'),
    passwordConfirmation: Yup.string()
      .required('Please confirm new password')
      .oneOf([Yup.ref('password'), ''], 'Passwords must match'),
  })

  if (with_old_password) {
    schema = schema.concat(
      Yup.object({
        oldPassword: Yup.string().required('Please enter your old password'),
      }),
    )
  }

  return schema
}
