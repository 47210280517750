import { useCallback, useState } from 'react'

export type ModalFunc<T> = (values?: Partial<T>) => void
type HookState<T> = {
  hide: ModalFunc<T>
  open: ModalFunc<T>
  state: T & { show: boolean }
}
export function useModalState<T extends Record<string, unknown>>(initialValue: T): HookState<T>
export function useModalState(): HookState<Record<string, unknown>>
export function useModalState(defaultValue = {}): {
  hide: (values: Record<string, unknown>) => void
  open: (values: Record<string, unknown>) => void
  state: {
    show: boolean
  }
} {
  const [state, setState] = useState({ show: false, ...defaultValue })

  // These should be able to be computed automatically, but for some reason it can't.
  // Investigate later, or don't! I'm not your dad.
  // AMJ - 12/31/20
  return {
    hide: useCallback(values => setState(state => ({ ...state, ...values, show: false })), []),
    open: useCallback(values => setState(state => ({ ...state, ...values, show: true })), []),
    state,
  }
}
