import { PropsWithChildren } from 'react'
import classnames from 'classnames'

import style from './TransparentButton.module.scss'

interface TransparentButtonInterface {
  className?: string
  onClick: () => void
}

export const TransparentButton = ({
  children,
  onClick,
  className,
  ...props
}: PropsWithChildren<TransparentButtonInterface>): JSX.Element => (
  <button
    type="button"
    className={classnames(style.TransparentButton, className)}
    onClick={onClick}
    {...props}
  >
    {children}
  </button>
)
