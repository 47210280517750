import { Redirect, Route, RouteProps, useLocation } from 'react-router-dom'

import { loginPath, signOutPath } from '../Router/Routes'
import { useAppContext } from '../../hooks/useAppContext'
import { UNAUTHORIZED_ROUTES } from '../../Routes'

export const ProtectedRoute = ({ children, ...props }: RouteProps): JSX.Element => {
  const { user, initializing } = useAppContext()
  const { pathname } = useLocation()

  return (
    <Route {...props}>
      {initializing ? null : !user ? (
        <Redirect
          to={{
            pathname: loginPath,
            state: {
              referrer: pathname === signOutPath ? undefined : pathname,
            },
          }}
        />
      ) : user.passwordExpired ? (
        <Redirect
          to={{
            pathname: UNAUTHORIZED_ROUTES.passwordExpired,
            state: { referrer: pathname },
          }}
        />
      ) : (
        children
      )}
    </Route>
  )
}
